<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        top="3vh"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="100px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect v-model="ajaxParam.companyId" />
            </el-form-item>
            <el-form-item :label="`${'标题'}:`" prop="title">
                <el-input
                    v-model="ajaxParam.title"
                    placeholder="请输入标题"
                    maxlength="16"
                ></el-input>
            </el-form-item>
            <el-form-item :label="`${'知识类别'}:`" prop="knowledgeCategory">
                <GlobalDictSelect
                    v-model="ajaxParam.knowledgeCategory"
                    placeholder="请选择知识类别"
                    parentCode="KNOWLEDGE_CATEGORY"
                />
            </el-form-item>
            <el-form-item :label="`${'封面'}:`" :rules="rules.fileList">
                <GlobalElUpload v-model="fileList" :limit="1"></GlobalElUpload>
            </el-form-item>
            <el-form-item :label="`${'内容'}:`" prop="content">
                <GlobalRichText @get-content="getContent">
                    <div v-html="content"></div>
                </GlobalRichText>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { fileUrl } from "@/api/Global";
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            fileUrl,
            fileList: [],
            content: "",
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId,
                content: "",
                filePath: "",
                knowledgeCategory: "",
                title: "",
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        getContent(v) {
            console.log(v.content);
            this.ajaxParam.content = v.content;
            // this.form.content = v.content;
            //       this.form.content = v.noHtml;
        },
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/info/server/escort/web/knowledgeBase/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                    this.content = data.content;
                    this.fileList = [
                        {
                            url: this.fileUrl + "/" + data.filePath,
                            uid: data.filePath,
                            filePath: data.filePath,
                        },
                    ];
                });
            }
        },
        async submitData() {
            const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            ajaxParam.filePath = this.fileList[0].filePath;
            let formData = new FormData();
            formData.append("companyId", this.ajaxParam.companyId);
            formData.append("content", this.ajaxParam.content);
            formData.append("filePath", this.fileList[0].filePath);
            formData.append(
                "knowledgeCategory",
                this.ajaxParam.knowledgeCategory
            );
            formData.append("title", this.ajaxParam.title);

            // 提交(编辑)数据
            let { code } = await this.$api.post(
                "/info/server/escort/web/knowledgeBase",
                formData,
                {
                    params: formData,
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }
            );
            if (code == 200) {
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.$emit("query");
                this.onCancel();
            }
        },

        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    // padding: 0 100px;
    padding-right: 20px;
    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
</style>
